import React, { useState, useEffect } from "react";
import Service from "../service/Service";
import Loader from "../service/Loader";
import { useForm } from "react-hook-form";
import * as moment from 'moment'

function EditBooking() {
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [roomsData, setRoomsData] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    watch,
  } = useForm();

  useEffect(() => {
    loadRoomData();
    loadDataforEdit();
  }, []);

  const loadRoomData = () => {
    setIsLoading(true);
    Service.getRoomStatus().then((res) => {
      setRoomsData(res.data);
      setIsLoading(false);
    });
  };

  const loadDataforEdit = () => {
    var data = JSON.parse(localStorage.getItem("BookingForEdit"));
    reset(data);
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    var editData = JSON.parse(localStorage.getItem("BookingForEdit"));
    data.BId = editData.BId;
    Service.updateBooking(data).then((res) => {
      if (res.data.success) {
        reset(null);
        setShowMsg(true);
        setMsgClass("alert alert-secondary solid alert-dismissible fade show");
        setMsgText(res.data.success);
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger solid alert-dismissible fade show");
        setMsgText(res.data.warning);
      }
      setIsLoading(false);
    });
  };

  const loadSingleRoom = (e) => {
    setIsLoading(true);
    Service.getSingleRoom(e.target.value).then((res) => {
      setValue("Rate", res.data[0].Rate, {
        shouldValidate: true,
      });
      setIsLoading(false);
      calculateTotal();
    });
  };

  const calculateTotal = () => {
    setValue("TotalAmount", getValues("Rate") * getValues("TotalStayDays"), {
      shouldValidate: true,
    });
    setValue("PendingAmt", getValues("TotalAmount") - getValues("Deposit"), {
      shouldValidate: true,
    });
  };

  const totalPersons = () => {
    setValue(
      "TotalPersons",
      parseInt(getValues("Males")) +
        parseInt(getValues("Females")) +
        parseInt(getValues("Childrens")),
      {
        shouldValidate: true,
      }
    );
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Edit Booking</h4>
                </div>

                <div className="card-body">
                  {showMsg == true ? (
                    <div className={msgClass}>
                      <strong>{msgText}</strong>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="btn-close"
                      ></button>
                    </div>
                  ) : null}

                  <div>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="needs-validation"
                    >
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="mb-3 row">
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Select Room :
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-10">
                              <select
                                className="default-select wide form-control"
                                {...register("RId", { required: true })}
                                onChange={(e) => loadSingleRoom(e)}
                              >
                                <option value="">Select Room</option>
                                {roomsData.map((item, i) => (
                                  <option key={i} value={item.RId}>
                                    {item.RNo}
                                  </option>
                                ))}
                              </select>

                              {errors.RId && errors.RId.type === "required" && (
                                <span className="text-danger">
                                  Select room no.
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="mb-3 row">
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Customer Name :
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter customer name."
                                {...register("CustName", { required: true })}
                              />
                              {errors.CustName &&
                                errors.CustName.type === "required" && (
                                  <span className="text-danger">
                                    Enter customer name.
                                  </span>
                                )}
                            </div>

                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Contact No :<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                placeholder="Enter contact no."
                                {...register("Contact", {
                                  required: true,
                                  pattern: /^[0-9]*$/,
                                })}
                              />
                              {errors.Contact &&
                                errors.Contact.type === "required" && (
                                  <span className="text-danger">
                                    Enter contact no.
                                  </span>
                                )}
                              {errors.Contact &&
                                errors.Contact.type === "pattern" && (
                                  <span className="text-danger">
                                    Enter numbers only.
                                  </span>
                                )}
                            </div>
                          </div>

                          <div className="mb-3 row">
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Document :<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <label class="radio-inline me-3">
                                <input
                                  type="radio"
                                  name="optradio"
                                  {...register("Document", { required: true })}
                                  value={'Aadhar Card'}
                                />{" "}
                                Aadhar Card
                              </label>
                              <label class="radio-inline me-3">
                                <input
                                  type="radio"
                                  name="optradio"
                                  {...register("Document", { required: true })}
                                  value={'Voter ID'}
                                />{" "}
                                Voter ID
                              </label>
                              <label class="radio-inline me-3">
                                <input
                                  type="radio"
                                  name="optradio"
                                  {...register("Document", { required: true })}
                                  value={'PAN Card'}
                                />{" "}
                                PAN Card
                              </label>
                              {errors.Document &&
                                errors.Document.type === "required" && (
                                  <span className="text-danger">
                                    Select document
                                  </span>
                                )}
                            </div>

                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom02"
                            >
                              Address :<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom02"
                                placeholder="Enter address."
                                {...register("Address", { required: true })}
                              />
                              {errors.Address &&
                                errors.Address.type === "required" && (
                                  <span className="text-danger">
                                    Enter address.
                                  </span>
                                )}
                            </div>
                          </div>

                          <div className="mb-3 row">
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Male :<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                placeholder="Enter no of male"
                                {...register("Males", {
                                  required: true,
                                  pattern: /^[0-9]*$/,
                                })}
                                onBlur={() => totalPersons()}
                              />
                              {errors.Males &&
                                errors.Males.type === "required" && (
                                  <span className="text-danger">
                                    Enter no of male.
                                  </span>
                                )}
                              {errors.Males &&
                                errors.Males.type === "pattern" && (
                                  <span className="text-danger">
                                    Enter only numbers.
                                  </span>
                                )}
                            </div>

                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom02"
                            >
                              Total Female :
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom02"
                                placeholder="Enter no of female."
                                {...register("Females", {
                                  required: true,
                                  pattern: /^[0-9]*$/,
                                })}
                                onBlur={() => totalPersons()}
                              />
                              {errors.Females &&
                                errors.Females.type === "required" && (
                                  <span className="text-danger">
                                    Enter no of females.
                                  </span>
                                )}
                              {errors.Females &&
                                errors.Females.type === "pattern" && (
                                  <span className="text-danger">
                                    Enter only numbers.
                                  </span>
                                )}
                            </div>
                          </div>

                          <div className="mb-3 row">
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Total Childrens :
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                placeholder="Enter no of childrens"
                                {...register("Childrens", {
                                  required: true,
                                  pattern: /^[0-9]*$/,
                                })}
                                onBlur={() => totalPersons()}
                              />
                              {errors.Childrens &&
                                errors.Childrens.type === "required" && (
                                  <span className="text-danger">
                                    Enter no of childrens.
                                  </span>
                                )}
                              {errors.Childrens &&
                                errors.Childrens.type === "pattern" && (
                                  <span className="text-danger">
                                    Enter only numbers.
                                  </span>
                                )}
                            </div>

                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom02"
                            >
                              Total Persons :
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                id="validationCustom02"
                                placeholder="Enter total persons."
                                {...register("TotalPersons", {
                                  required: true,
                                })}
                              />
                              {errors.TotalPersons &&
                                errors.TotalPersons.type === "required" && (
                                  <span className="text-danger">
                                    Enter total persons.
                                  </span>
                                )}
                            </div>
                          </div>

                          <div className="mb-3 row">
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Checkin Date-Time :
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-2">
                              <input
                                type="date"
                                className="form-control"
                                id="validationCustom01"
                                placeholder="Enter checkin date."
                                {...register("CheckInDate", {
                                  required: true,
                                })}
                                onChange={() => calculateTotal()}
                              />
                              {errors.CheckInDate &&
                                errors.CheckInDate.type === "required" && (
                                  <span className="text-danger">
                                    Enter checkin date.
                                  </span>
                                )}
                            </div>
                            <div className="col-lg-2">
                              <input
                                type="time"
                                className="form-control"
                                id="validationCustom01"
                                {...register("CheckInTime", {
                                  required: true,
                                })}
                              />
                              {errors.CheckInTime &&
                                errors.CheckInTime.type === "required" && (
                                  <span className="text-danger">
                                    Enter time.
                                  </span>
                                )}
                            </div>

                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom02"
                            >
                              Checkout Date-Time :
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-2">
                              <input
                                type="date"
                                className="form-control"
                                id="validationCustom02"
                                placeholder="Enter check out date."
                                {...register("CheckOutDate", {
                                  required: true,
                                })}
                                onChange={() => calculateTotal()}
                              />
                              {errors.CheckOutDate &&
                                errors.CheckOutDate.type === "required" && (
                                  <span className="text-danger">
                                    Enter checkout date.
                                  </span>
                                )}
                            </div>
                            <div className="col-lg-2">
                              <input
                                type="time"
                                className="form-control"
                                id="validationCustom01"
                                {...register("CheckOutTime", {
                                  required: true,
                                })}
                              />
                              {errors.CheckOutTime &&
                                errors.CheckOutTime.type === "required" && (
                                  <span className="text-danger">
                                    Enter time.
                                  </span>
                                )}
                            </div>
                          </div>

                          <div className="mb-3 row">
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Total days to stay :
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                placeholder="Enter total days."
                                {...register("TotalStayDays", {
                                  required: true,
                                })}
                                onBlur={() => calculateTotal()}
                              />
                              {errors.TotalStayDays &&
                                errors.TotalStayDays.type === "required" && (
                                  <span className="text-danger">
                                    Enter total stay days.
                                  </span>
                                )}
                            </div>

                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Rate Per Day :
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                placeholder="Enter rate."
                                {...register("Rate", {
                                  required: true,
                                })}
                                onBlur={() => calculateTotal()}
                              />
                              {errors.Rate &&
                                errors.Rate.type === "required" && (
                                  <span className="text-danger">
                                    Enter rate.
                                  </span>
                                )}
                            </div>
                          </div>

                          <div className="mb-3 row">
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom02"
                            >
                              Total Amount :
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                id="validationCustom02"
                                placeholder="Enter total amount."
                                {...register("TotalAmount", {
                                  required: true,
                                })}
                              />
                              {errors.TotalAmount &&
                                errors.TotalAmount.type === "required" && (
                                  <span className="text-danger">
                                    Enter total amount.
                                  </span>
                                )}
                            </div>

                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom02"
                            >
                              Deposit :<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom02"
                                placeholder="Enter deposit amount."
                                {...register("Deposit", {
                                  required: true,
                                })}
                                onBlur={() => calculateTotal()}
                              />
                              {errors.Deposit &&
                                errors.Deposit.type === "required" && (
                                  <span className="text-danger">
                                    Enter deposit.
                                  </span>
                                )}
                            </div>
                          </div>

                       
                          <div className="mb-3 row">
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Pending Amount :
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                placeholder="Enter pending amount."
                                {...register("PendingAmt", {
                                  required: true,
                                })}
                              />
                              {errors.PendingAmt &&
                                errors.PendingAmt.type === "required" && (
                                  <span className="text-danger">
                                    Enter pending amount.
                                  </span>
                                )}
                            </div>
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Vehicle No :<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                placeholder="Enter vehicle no."
                                {...register("VehicleNo", {
                                  required: true,
                                })}
                              />
                              {errors.VehicleNo &&
                                errors.VehicleNo.type === "required" && (
                                  <span className="text-danger">
                                    Enter vehicle no.
                                  </span>
                                )}
                            </div>
                          </div>

                          <div className="mb-3 row">
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom02"
                            >
                              Occupation :<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom02"
                                placeholder="Enter occupation."
                                {...register("Occupation", {
                                  required: true,
                                })}
                              />
                              {errors.Occupation &&
                                errors.Occupation.type === "required" && (
                                  <span className="text-danger">
                                    Enter occupation.
                                  </span>
                                )}
                            </div>
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Visit Reason :
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                placeholder="Enter visit reason."
                                {...register("VisitReason", {
                                  required: true,
                                })}
                              />
                              {errors.VisitReason &&
                                errors.VisitReason.type === "required" && (
                                  <span className="text-danger">
                                    Enter visit reason.
                                  </span>
                                )}
                            </div>
                          </div>


                          <div className="mb-3 row">
                            <div className="col-lg-10"></div>
                            <div className="col-lg-2">
                              <button type="submit" className="btn btn-primary">
                                Update Booking
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditBooking;
