import React, { useState, useEffect } from "react";
import Service from "../service/Service";
import Loader from "../service/Loader";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

function Login() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    watch,
  } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);
    Service.login(data).then((res) => {
      if (res.data.success) {
        reset(null);
        setShowMsg(true);
        setMsgClass("alert alert-secondary solid alert-dismissible fade show");
        setMsgText(res.data.success);
        localStorage.setItem("UserLogin", JSON.stringify(res.data));
        history.push("/home");
        window.location.reload();
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-warning solid alert-dismissible fade show");
        setMsgText(res.data.warning);
      }
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="vh-100">
        <div className="authincation h-100">
          <div className="container h-100">
            <div className="row justify-content-center h-100 align-items-center">
              <div className="col-md-6">
                <div className="authincation-content">
                  <div className="row no-gutters">
                    <div className="col-xl-12">
                      <div className="auth-form">
                        <div className="text-center mb-3">
                          <a href="index.html">
                            <img src="images/logo-full.png" alt="" />
                          </a>
                        </div>
                        <h4 className="text-center mb-4">
                          Sign in your account
                        </h4>
                        {showMsg == true ? (
                          <div className={msgClass}>
                            <strong>{msgText}</strong>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="alert"
                              aria-label="btn-close"
                            ></button>
                          </div>
                        ) : null}
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="mb-3">
                            <label className="mb-1">
                              <strong>Contact</strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter username."
                              {...register("Contact", { required: true })}
                            />
                            {errors.Contact &&
                              errors.Contact.type === "required" && (
                                <span className="text-danger">
                                  Enter username.
                                </span>
                              )}
                          </div>
                          <div className="mb-3">
                            <label className="mb-1">
                              <strong>Password</strong>
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Enter password."
                              {...register("Pass", { required: true })}
                            />
                            {errors.Pass &&
                              errors.Pass.type === "required" && (
                                <span className="text-danger">
                                  Enter password.
                                </span>
                              )}
                          </div>
                          <div className="row d-flex justify-content-between mt-4 mb-2">
                            <div className="mb-3">
                              <div className="form-check custom-checkbox ms-1">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="basic_checkbox_1"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="basic_checkbox_1"
                                >
                                  Remember my preference
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block"
                            >
                              Login
                            </button>
                          </div>
                        </form>
                        {/* <div className="new-account mt-3">
                          <p>
                            Don't have an account?{" "}
                            <a
                              className="text-primary"
                              href="page-register.html"
                            >
                              Sign up
                            </a>
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
