import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Service from "../service/Service";
import Loader from "../service/Loader";
import { useHistory } from "react-router-dom";

function Site(props) {
  const history = useHistory();
  const logout = () => {
    if (window.confirm("Are you sure you want to logout..!")) {
      localStorage.removeItem("UserLogin");
      history.push("/login");
    }
  };

  return (
    <div id="main-wrapper">
      <div className="nav-header">
        <a href="/" className="brand-logo">
          <svg
            className="logo-abbr"
            xmlns="http://www.w3.org/2000/svg"
            width="47.426"
            height="56.91"
            viewBox="0 0 47.426 56.91"
          >
            <g
              id="Group_25"
              data-name="Group 25"
              transform="translate(-56 -32)"
            >
              <path
                id="hotel"
                d="M49.737,55.935a.948.948,0,0,1-.948.976h-5A3.794,3.794,0,0,1,40,53.116V34.146a3.794,3.794,0,0,1,3.794-3.794h4.98a.949.949,0,0,1,.949.949C49.717,49.164,49.687,54.3,49.737,55.935Zm-3.22-16.1a.949.949,0,1,0-1.9,0v4.031a.949.949,0,0,0,1.9,0Zm40.9-5.691v18.97a3.794,3.794,0,0,1-3.794,3.794h-5a.948.948,0,0,1-.948-.976c.05-1.634.019-6.76.018-24.633a.949.949,0,0,1,.949-.949h4.98A3.794,3.794,0,0,1,87.422,34.146ZM82.8,39.837a.949.949,0,1,0-1.9,0v4.031a.949.949,0,0,0,1.9,0ZM61.338,56.91H66.08a.949.949,0,0,0,.949-.949V47.07a.949.949,0,0,0-.949-.949H61.338a.949.949,0,0,0-.949.949v8.892A.949.949,0,0,0,61.338,56.91ZM75.8,20.63V55.962a.949.949,0,0,1-.949.949h-4.98a.949.949,0,0,1-.949-.949V45.172a.949.949,0,0,0-.949-.949H59.441a.949.949,0,0,0-.949.949V55.962a.949.949,0,0,1-.949.949h-4.98a.949.949,0,0,1-.949-.949V20.63a3.794,3.794,0,0,1,3.794-3.794h16.6A3.794,3.794,0,0,1,75.8,20.63ZM68.452,36.517a.949.949,0,0,0-.949-.949H59.915a.949.949,0,0,0,0,1.9H67.5A.948.948,0,0,0,68.452,36.517Zm1.66-9.129a.949.949,0,0,0-.949-.949H58.255a.949.949,0,1,0,0,1.9H69.163A.948.948,0,0,0,70.111,27.388Z"
                transform="translate(16.004 32)"
                fill="#135846"
              />
              <path
                id="hotel-2"
                data-name="hotel"
                d="M70.2,5.874l-2.557,2.5.6,3.533a.949.949,0,0,1-.935,1.109c-.321,0-.154.043-3.6-1.776l-3.159,1.667a.95.95,0,0,1-1.378-1l.6-3.533-2.557-2.5a.949.949,0,0,1,.526-1.617l3.531-.515L62.858.529a.949.949,0,0,1,1.7,0l1.58,3.213,3.531.515A.949.949,0,0,1,70.2,5.874ZM53.812,10.728l-.112-.653c.439-.428.93-.772.715-1.435s-.82-.653-1.422-.741L52.7,7.3a.949.949,0,0,0-1.7,0l-.293.594c-.606.088-1.206.078-1.422.741s.279,1.011.715,1.435l-.112.653a.949.949,0,0,0,1.376,1l.587-.309c.6.316.732.417,1.028.417A.949.949,0,0,0,53.812,10.728Zm-8.3,4.743-.112-.653c.439-.428.93-.772.715-1.435s-.82-.653-1.422-.741l-.293-.594a.949.949,0,0,0-1.7,0l-.293.594c-.606.088-1.206.078-1.422.741s.279,1.011.715,1.435l-.112.653a.949.949,0,0,0,1.376,1l.587-.309c.6.316.732.417,1.028.417a.949.949,0,0,0,.935-1.109Zm32.02-4.743-.112-.653c.439-.428.93-.772.715-1.435s-.82-.653-1.422-.741c-.271-.55-.447-1.123-1.144-1.123s-.875.578-1.144,1.123l-.656.1a.949.949,0,0,0-.526,1.618l.475.463c-.1.6-.3,1.171.265,1.581s1.047.047,1.586-.236c.6.316.732.417,1.028.417a.949.949,0,0,0,.935-1.109Zm8.3,4.743-.112-.653c.439-.428.93-.772.715-1.435s-.82-.653-1.422-.741c-.271-.55-.447-1.123-1.144-1.123s-.875.578-1.144,1.123l-.656.1a.949.949,0,0,0-.526,1.618l.475.463c-.1.6-.3,1.171.265,1.581s1.047.047,1.586-.236c.6.316.732.417,1.028.417A.949.949,0,0,0,85.831,15.471Z"
                transform="translate(16.004 32)"
                fill="var(--primary)"
              />
            </g>
          </svg>
          <svg
            className="brand-title"
            xmlns="http://www.w3.org/2000/svg"
            width="143"
            height="46.359"
            viewBox="0 0 143 46.359"
          >
            <g
              id="Group_26"
              data-name="Group 26"
              transform="translate(-134 -40.641)"
            ></g>
          </svg>
        </a>
        <div className="nav-control">
          <div className="hamburger">
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
      </div>

      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
                <div className="dashboard_bar">Dashboard</div>
              </div>

              <ul className="navbar-nav header-right">
                <li className="nav-item dropdown header-profile">
                  <a
                    className="nav-link"
                    href="http://"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    <img src="images/profile/profile.png" width="20" alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>

      <div className="dlabnav">
        <div className="dlabnav-scroll">
          <ul className="metismenu" id="menu">
            <li>
              <Link to="/home" aria-expanded="false">
                <i className="flaticon-025-dashboard"></i>
                <span className="nav-text">Ghati Room Status</span>
              </Link>
            </li>
            <li>
              <Link to="/" aria-expanded="false">
                <i className="flaticon-025-dashboard"></i>
                <span className="nav-text">Ghati Registration</span>
              </Link>
            </li>

            <li>
              <Link to="/rooms" aria-expanded="false">
                <i className="flaticon-022-copy"></i>
                <span className="nav-text">Room Master</span>
              </Link>
            </li>
            <li>
              <Link to="/new-booking" aria-expanded="false">
                <i className="flaticon-072-printer"></i>
                <span className="nav-text">New Booking</span>
              </Link>
            </li>
            <li>
              <Link to="/view" aria-expanded="false">
                <i className="flaticon-050-info"></i>
                <span className="nav-text">Check In Details</span>
              </Link>
            </li>
            <li>
              <Link to="/checkout-details" aria-expanded="false">
                <i className="flaticon-050-info"></i>
                <span className="nav-text">Check Out Details</span>
              </Link>
            </li>

            <li>
              <a role="button" onClick={() => logout()} aria-expanded="false">
                <i className="flaticon-013-checkmark"></i>
                <span className="nav-text">Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      {props.children}

      <div className="footer">
        <div className="copyright">
          <p>
            Copyright © Designed &amp; Developed by{" "}
            <a href="https://arnavtechnosys.com/" target="_blank">
              Arnav Technosys
            </a>{" "}
            2021
          </p>
        </div>
      </div>
    </div>
  );
}

export default Site;
