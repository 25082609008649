import React, { useState, useEffect } from "react";
import Service from "../service/Service";
import Loader from "../service/Loader";
import { useHistory } from "react-router-dom";
import jsPDF from "jspdf";
import * as moment from "moment";

function ViewDetails() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [roomsData, setRoomsData] = useState([]);
  const [bookingsData, setBookingsData] = useState([]);

  useEffect(() => {
    loadRoomData();
  }, []);

  const loadRoomData = () => {
    setIsLoading(true);
    Service.getRoomStatus().then((res) => {
      setRoomsData(res.data);
      setIsLoading(false);
    });
  };

  const loadBookingsByRoom = (value) => {
    if (value) {
      setIsLoading(true);
      Service.getBookingByRoom(value).then((res) => {
        setBookingsData(res.data);
        setIsLoading(false);
      });
    }
  };

  const loadBookingsBySearch = (value) => {
    //setIsLoading(true);
    Service.getBookingBySearch(value).then((res) => {
      setBookingsData(res.data);
      //setIsLoading(false);
    });
  };

  const action = (status, data) => {
    if (status == "Print") {
      localStorage.setItem("PrintData", JSON.stringify(data));
      const win = window.open("/print", "_blank");
      win.focus();
      //history.push("/print");
    }
    if (status == "Checkout") {
      if (
        window.confirm(
          "Confirm are you checkout to : " + data.CustName + " ..?"
        )
      ) {
        setIsLoading(true);
        data.CheckInDate = moment(data.CheckInDate).format("YYYY/MM/DD");
        data.CheckOutDate = moment(data.CheckOutDate).format("YYYY/MM/DD");
        Service.checkoutBooking(data).then((res) => {
          if (res.data.success) {
            setShowMsg(true);
            setMsgClass(
              "alert alert-secondary solid alert-dismissible fade show"
            );
            setMsgText(res.data.success);
            loadBookingsByRoom("All");
          }
          setIsLoading(false);
        });
      }
    }
    if (status == "Paid") {
      if (
        window.confirm(
          "Confirm are you sure update amount of : " + data.CustName + " ..?"
        )
      ) {
        setIsLoading(true);
        data.Deposit = data.TotalAmount;
        data.PendingAmt = 0;
        Service.paidBooking(data).then((res) => {
          if (res.data.success) {
            setShowMsg(true);
            setMsgClass(
              "alert alert-secondary solid alert-dismissible fade show"
            );
            setMsgText(res.data.success);
            loadBookingsByRoom("All");
          }
          setIsLoading(false);
        });
      }
    }

    if (status == "Edit") {
      if (
        window.confirm(
          "Confirm are you sure to edit record of : " + data.CustName + " ..?"
        )
      ) {
        localStorage.setItem("BookingForEdit", JSON.stringify(data));
        history.push("/edit-booking");
      }
    }

    if (status == "Delete") {
      if (
        window.confirm(
          "Confirm are you sure to delete record of : " + data.CustName + " ..?"
        )
      ) {
        setIsLoading(true);
        data.Deposit = data.TotalAmount;
        data.PendingAmt = 0;
        Service.deleteBooking(data.BId).then((res) => {
          if (res.data.success) {
            setShowMsg(true);
            setMsgClass(
              "alert alert-secondary solid alert-dismissible fade show"
            );
            setMsgText(res.data.success);
            loadBookingsByRoom("All");
          }
          setIsLoading(false);
        });
      }
    }
  };

  function generatePdf(item) {
    var doc = new jsPDF("portrait", "pt", "a4", "false");
    // A4 Page size in point width=595 x height=842
    //Important Site for study
    // https://mrrio.github.io/jsPDF/examples/basic.html

    //doc.setFontSize(22);
    doc.text("Jagdamba Mata Devsthan Trust, Kotamgaon", 298, 30, "center");
    doc.line(575, 40, 20, 40);

    doc.setFontSize(10);
    doc.text("Customer Name : ", 30, 65);
    doc.setFont(undefined, "bold");
    doc.text(item.CustName, 110, 65);

    //doc.text("Date : ", 30 , 65);
    doc.setFont(undefined, "bold");
    doc.text(moment(new Date()).format("MMMM Do YYYY"), 565, 65, "right");
    doc.setFont(undefined, "normal");
    doc.text("Print Date : ", 465, 65, "right");

    doc.setFont(undefined, "bold");
    doc.text(
      moment(item.CheckInDate).format("MMMM Do YYYY") +
        " " +
        tConv24(item.CheckInTime),
      565,
      85,
      "right"
    );
    doc.setFont(undefined, "normal");
    doc.text("Check In Date : ", 415, 85, "right");

    doc.setFont(undefined, "bold");
    doc.text(
      moment(item.CheckOutDate).format("MMMM Do YYYY") +
        " " +
        tConv24(item.CheckOutTime),
      565,
      105,
      "right"
    );
    doc.setFont(undefined, "normal");
    doc.text("Check Out Date : ", 415, 105, "right");

    doc.text("Contact : ", 30, 85);
    doc.setFont(undefined, "bold");
    doc.text(item.Contact, 75, 85);

    doc.setFont(undefined, "normal");
    doc.text("Address : ", 30, 105);
    doc.setFont(undefined, "bold");
    doc.text(item.Address, 75, 105);

    doc.setFont(undefined, "normal");
    doc.text("Total Persons : ", 30, 125);
    doc.setFont(undefined, "bold");
    doc.text(item.TotalPersons.toString(), 100, 125);

    doc.setFont(undefined, "normal");
    doc.text("Rate Per Day : ", 30, 145);
    doc.setFont(undefined, "bold");
    doc.text("Rs. " + item.Rate.toString() + " /-", 100, 145);

    doc.setFont(undefined, "normal");
    doc.text("Total Days to Stay : ", 30, 165);
    doc.setFont(undefined, "bold");
    doc.text(item.TotalStayDays.toString(), 120, 165);

    doc.setFont(undefined, "normal");
    doc.text("Total Amount : ", 30, 185);
    doc.setFont(undefined, "bold");
    doc.text("Rs. " + item.TotalAmount.toString() + " /-", 100, 185);

    doc.setFont(undefined, "normal");
    doc.text("Amount Paid : ", 30, 205);
    doc.setFont(undefined, "bold");
    doc.text("Rs. " + item.Deposit.toString() + " /-", 100, 205);

    doc.setFont(undefined, "normal");
    doc.text("Pending Amount : ", 30, 225);
    doc.setFont(undefined, "bold");
    doc.text("Rs. " + item.PendingAmt.toString() + " /-", 110, 225);

    doc.line(575, 245, 20, 245);

    doc.setFont(undefined, "normal");
    doc.text(
      "Welcome to Jagdamba Mata Devsthan Trust, Kotamgaon",
      298,
      265,
      "center"
    );
    doc.setFont(undefined, "bold");
    doc.text(
      "If any query please contact us : Mr. Rajendra Kotame : +91 77690 31307",
      298,
      285,
      "center"
    );

    doc.line(575, 305, 20, 305);

    // doc.save(item.CustName+" Receipt.pdf");
    doc.autoPrint(); // <<--------------------- !!
    // doc.output("dataurlnewwindow");
    window.open(doc.output("bloburl"), "_blank");
  }

  function tConv24(time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  }

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">List of CheckIn Bookings</h4>
                </div>

                <div className="card-body">
                  {showMsg == true ? (
                    <div className={msgClass}>
                      <strong>{msgText}</strong>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="btn-close"
                      ></button>
                    </div>
                  ) : null}

                  <div>
                    <form className="needs-validation">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="mb-3 row">
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Select Room :
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <select
                                className="default-select wide form-control"
                                onChange={(e) =>
                                  loadBookingsByRoom(e.target.value)
                                }
                              >
                                <option value="">Search by room</option>
                                <option value="All">All Bookings</option>
                                {roomsData.map((item, i) => (
                                  <option key={i} value={item.RId}>
                                    {item.RNo}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Search By Name :
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search by customer name."
                                onChange={(e) =>
                                  loadBookingsBySearch(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="card-header">
                    <h4 className="card-title"></h4>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-responsive-md">
                      <thead>
                        <tr>
                          <th>
                            <strong>#</strong>
                          </th>
                          <th>
                            <strong>Customer</strong>
                          </th>
                          <th>
                            <strong>Contact</strong>
                          </th>
                          <th>
                            <strong>Check-In</strong>
                          </th>
                          <th>
                            <strong>Check-Out</strong>
                          </th>
                          <th>
                            <strong>Rate</strong>
                          </th>
                          <th>
                            <strong>Total</strong>
                          </th>
                          <th>
                            <strong>Pending</strong>
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookingsData.map((item, i) => (
                          <tr key={i}>
                            <td>
                              <strong>{i + 1}</strong>
                            </td>
                            <td>{item.CustName}</td>
                            <td>{item.Contact}</td>
                            <td>
                              {moment(item.CheckInDate).format("DD/MM/YYYY")}
                              <br />
                              {tConv24(item.CheckInTime)}
                            </td>
                            <td>
                              {moment(item.CheckOutDate).format("DD/MM/YYYY")}
                              <br />
                              {tConv24(item.CheckOutTime)}
                            </td>
                            <td>{item.Rate}</td>
                            <td>{item.TotalAmount}</td>
                            <td>{item.PendingAmt}</td>
                            <td>
                              <div className="dropdown">
                                <button
                                  type="button"
                                  className="btn btn-success light sharp"
                                  data-bs-toggle="dropdown"
                                >
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      />
                                      <circle
                                        fill="#000000"
                                        cx="5"
                                        cy="12"
                                        r="2"
                                      />
                                      <circle
                                        fill="#000000"
                                        cx="12"
                                        cy="12"
                                        r="2"
                                      />
                                      <circle
                                        fill="#000000"
                                        cx="19"
                                        cy="12"
                                        r="2"
                                      />
                                    </g>
                                  </svg>
                                </button>
                                <div className="dropdown-menu">
                                  <a
                                    href="http://"
                                    className="dropdown-item"
                                    onClick={() => generatePdf(item)}
                                  >
                                    Print
                                  </a>
                                  <a
                                    href="http://"
                                    className="dropdown-item"
                                    onClick={() => action("Checkout", item)}
                                  >
                                    Checkout
                                  </a>
                                  <a
                                    href="http://"
                                    className="dropdown-item"
                                    onClick={() => action("Paid", item)}
                                  >
                                    Paid
                                  </a>
                                  <a
                                    href="http://"
                                    className="dropdown-item"
                                    onClick={() => action("Edit", item)}
                                  >
                                    Edit
                                  </a>
                                  <a
                                    href="http://"
                                    className="dropdown-item"
                                    onClick={() => action("Delete", item)}
                                  >
                                    Delete
                                  </a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewDetails;
