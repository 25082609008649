import React, { useState, useEffect } from "react";
import Service from "../service/Service";
import Loader from "../service/Loader";
import { useForm } from "react-hook-form";
import * as moment from "moment";

function ManageRooms() {
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [roomsData, setRoomsData] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    watch,
  } = useForm();

  useEffect(() => {
    loadRoomData();
  }, []);

  const loadRoomData = () => {
    setIsLoading(true);
    Service.getRoomStatus().then((res) => {
      setRoomsData(res.data);
      setIsLoading(false);
    });
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    Service.saveRoom(data)
      .then((res) => {
        if (res.data.success) {
          reset(null);
          setShowMsg(true);
          setMsgClass(
            "alert alert-secondary solid alert-dismissible fade show"
          );
          setMsgText(res.data.success);
          loadRoomData();
        } else {
          setShowMsg(true);
          setMsgClass("alert alert-danger solid alert-dismissible fade show");
          setMsgText(res.data.warning);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const editRecord = (data) => {
    reset(data);
  };

  const updateRecord = (data) => {
    if (!data.RId) {
      setShowMsg(true);
      setMsgClass("alert alert-danger solid alert-dismissible fade show");
      setMsgText("Select record for updated..!");
      return;
    }
    setIsLoading(true);
    Service.updateRooms(getValues())
      .then((res) => {
        if (res.data.success) {
          reset(null);
          setShowMsg(true);
          setMsgClass(
            "alert alert-secondary solid alert-dismissible fade show"
          );
          setMsgText(res.data.success);
          loadRoomData();
        } else {
          setShowMsg(true);
          setMsgClass("alert alert-danger solid alert-dismissible fade show");
          setMsgText(res.data.warning);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const deleteRecord = (data) => {
    if (window.confirm("Confirm are you sure to delete..?")) {
      setIsLoading(true);
      Service.deleteRoom(data.RId)
        .then((res) => {
          if (res.data.success) {
            setShowMsg(true);
            setMsgClass(
              "alert alert-secondary solid alert-dismissible fade show"
            );
            setMsgText(res.data.success);
            loadRoomData();
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Manage Rooms</h4>
                </div>

                <div className="card-body">
                  {showMsg == true ? (
                    <div className={msgClass}>
                      <strong>{msgText}</strong>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="btn-close"
                      ></button>
                    </div>
                  ) : null}

                  <div>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="needs-validation"
                    >
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="mb-3 row">
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Room No :<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter room no."
                                {...register("RNo", { required: true })}
                              />
                              {errors.RNo && errors.RNo.type === "required" && (
                                <span className="text-danger">
                                  Enter room no.
                                </span>
                              )}
                            </div>

                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Room Name :<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                placeholder="Enter room name."
                                {...register("RName", {
                                  required: true,
                                })}
                              />
                              {errors.RName &&
                                errors.RName.type === "required" && (
                                  <span className="text-danger">
                                    Enter room name.
                                  </span>
                                )}
                            </div>
                          </div>

                          <div className="mb-3 row">
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Rate :<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                placeholder="Enter rate."
                                {...register("Rate", {
                                  required: true,
                                  pattern: /^[0-9]*$/,
                                })}
                              />
                              {errors.Rate &&
                                errors.Rate.type === "required" && (
                                  <span className="text-danger">
                                    Enter rate.
                                  </span>
                                )}
                              {errors.Rate &&
                                errors.Rate.type === "pattern" && (
                                  <span className="text-danger">
                                    Enter only numbers.
                                  </span>
                                )}
                            </div>
                          </div>

                          <div className="mb-3 row">
                            <div className="col-lg-8"></div>
                            <div className="col-lg-2">
                              <button type="submit" className="btn btn-primary">
                                Save Room
                              </button>
                            </div>
                            <div className="col-lg-2">
                              <button
                                onClick={handleSubmit(updateRecord)}
                                type="button"
                                className="btn btn-secondary"
                              >
                                Update Room
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="card-header">
                    <h4 className="card-title"></h4>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-responsive-md">
                      <thead>
                        <tr>
                          <th>
                            <strong>#</strong>
                          </th>
                          <th>
                            <strong>Room No</strong>
                          </th>
                          <th>
                            <strong>Room Name</strong>
                          </th>
                          <th>
                            <strong>Rate</strong>
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {roomsData.map((item, i) => (
                          <tr key={i}>
                            <td>
                              <strong>{i + 1}</strong>
                            </td>
                            <td>{item.RNo}</td>
                            <td>{item.RName}</td>
                            <td>{item.Rate}</td>

                            <td>
                              <div className="d-flex">
                                <a
                                  onClick={() => editRecord(item)}
                                  href="http://"
                                  className="btn btn-danger shadow btn-xs sharp  me-1"
                                >
                                  <i className="fas fa-pencil-alt"></i>
                                </a>
                                <a
                                  onClick={() => deleteRecord(item)}
                                  href="http://"
                                  className="btn btn-danger shadow btn-xs sharp"
                                >
                                  <i className="fa fa-trash"></i>
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageRooms;
