import React, { useState, useEffect } from "react";
import Service from "../service/Service";
import Loader from "../service/Loader";
import { useForm } from "react-hook-form";
import * as moment from "moment";

function Registration() {
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [selectedRoom, setSelectedRoom] = useState("");
  const [rooms, setRooms] = useState([]);
  const [oldRoomData, setOldRoomData] = useState([]);
  const [filteredOldRoomData, setFilteredOldRoomData] = useState([]);
  const [newRoomData, setNewRoomData] = useState([]);
  const [filteredNewRoomData, setFiltereNewRoomData] = useState([]);
  const [regData, setRegData] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    watch,
  } = useForm();

  useEffect(() => {
    loadRooms();
    // loadOldRoomData();
    // loadNewRoomData();
  }, []);

  const handleSearchOldCustomers = (event) => {
    const text = event.target.value;
    if (text) {
      // const filtered = oldRoomData.filter((item) =>
      //   item.patientName.toLowerCase().includes(text.toLowerCase())
      // );
      const filtered = oldRoomData.filter((item) => {
        var search = new RegExp(text, "i");
        return (
          search.test(item.custName) ||
          search.test(item.roomNo) ||
          search.test(item.address) ||
          search.test(item.contact)
        );
      });
      setFilteredOldRoomData(filtered);
    } else {
      setFilteredOldRoomData(oldRoomData);
    }
  };

  const handleSearchNewCustomers = (event) => {
    const text = event.target.value;
    if (text) {
      // const filtered = oldRoomData.filter((item) =>
      //   item.patientName.toLowerCase().includes(text.toLowerCase())
      // );
      const filtered = newRoomData.filter((item) => {
        var search = new RegExp(text, "i");
        return (
          search.test(item.custName) ||
          search.test(item.roomNo) ||
          search.test(item.address) ||
          search.test(item.contact)
        );
      });
      setFiltereNewRoomData(filtered);
    } else {
      setFiltereNewRoomData(newRoomData);
    }
  };

  const loadRooms = () => {
    setIsLoading(true);
    Service.getAllRooms().then((res) => {
      setRooms(res.data);
      setIsLoading(false);
    });
  };

  const loadOldRoomData = () => {
    setIsLoading(true);
    Service.getAllOldCustomers().then((res) => {
      setOldRoomData(res.data);
      setFilteredOldRoomData(res.data);
      setIsLoading(false);
    });
  };

  const loadNewRoomData = () => {
    setIsLoading(true);
    Service.getAllCurrentCustomers().then((res) => {
      setNewRoomData(res.data);
      setFiltereNewRoomData(res.data);
      setIsLoading(false);
    });
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    data.amount = "200";
    console.log(data);

    Service.registerCustomers(data).then(
      (res) => {
        if (res.data.success) {
          reset();
          setShowMsg(true);
          setMsgClass(
            "alert alert-secondary solid alert-dismissible fade show"
          );
          setMsgText(res.data.success);
          data.regId = res.data.regId;
          setRegData(data);
        } else {
          setShowMsg(true);
          setMsgClass("alert alert-danger solid alert-dismissible fade show");
          setMsgText(res.data.warning);
        }
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        console.log(error);
      }
    );
  };

  const onUpdate = (data) => {
    setIsLoading(true);
    data.amount = "200";
    console.log(data);
    setRegData(data);
    Service.updateCustomers(data).then(
      (res) => {
        if (res.data.success) {
          reset();
          setShowMsg(true);
          setMsgClass(
            "alert alert-secondary solid alert-dismissible fade show"
          );
          setMsgText(res.data.success);
        } else {
          setShowMsg(true);
          setMsgClass("alert alert-danger solid alert-dismissible fade show");
          setMsgText(res.data.warning);
        }
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        console.log(error);
      }
    );
  };

  const onDelete = (data) => {
    setIsLoading(true);
    Service.deleteCustomers({ regId: data.regId }).then(
      (res) => {
        if (res.data.success) {
          reset();
          setShowMsg(true);
          setMsgClass(
            "alert alert-secondary solid alert-dismissible fade show"
          );
          setMsgText(res.data.success);
        } else {
          setShowMsg(true);
          setMsgClass("alert alert-danger solid alert-dismissible fade show");
          setMsgText(res.data.warning);
        }
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        console.log(error);
      }
    );
  };

  const setDataToPrint = (item) => {
    setRegData(item);
  };

  const setOldSearched = (item) => {
    reset(item);
  };

  const setNewSearched = (item) => {
    reset(item);
  };

  const printInvoice = () => {
    const prtContent = document.getElementById("print-invoice");
    prtContent.style.padding = "20px";
    const WinPrint = window.open(
      "",
      "",
      "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
    );
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Customer Registration</h4>
                </div>

                <div className="card-body">
                  {showMsg == true ? (
                    <div className={msgClass}>
                      <strong>{msgText}</strong>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="btn-close"
                      ></button>
                    </div>
                  ) : null}

                  <div>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="needs-validation"
                    >
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="mb-3 row">
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Select room no :
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <select
                                className="form-control"
                                {...register("roomNo", { required: true })}
                                // onChange={(e) => loadSingleRoom(e)}
                              >
                                <option value="">Select Room</option>
                                {rooms.map((item, i) => (
                                  <option key={i} value={item.roomNo}>
                                    {item.roomNo}
                                  </option>
                                ))}
                              </select>
                              {errors.roomNo &&
                                errors.roomNo.type === "required" && (
                                  <span className="text-danger">
                                    Select room no.
                                  </span>
                                )}
                            </div>

                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Customer Name :
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                placeholder="Enter customer name"
                                {...register("custName", {
                                  required: true,
                                })}
                              />
                              {errors.custName &&
                                errors.custName.type === "required" && (
                                  <span className="text-danger">
                                    Enter customer name
                                  </span>
                                )}
                            </div>
                          </div>

                          <div className="mb-3 row">
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Address :<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter customer address"
                                {...register("address", { required: true })}
                              />
                              {errors.address &&
                                errors.address.type === "required" && (
                                  <span className="text-danger">
                                    Enter address.
                                  </span>
                                )}
                            </div>

                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Customer age :
                              <span className="text-danger"></span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                placeholder="Enter customer age"
                                {...register("age")}
                              />
                              {/* {errors.age &&
                                errors.age.type === "required" && (
                                  <span className="text-danger">
                                    Enter customer age
                                  </span>
                                )} */}
                            </div>
                          </div>

                          <div className="mb-3 row">
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Contact No :<span className="text-danger"></span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter contact no"
                                {...register("contact")}
                              />
                              {/* {errors.address &&
                                errors.address.type === "required" && (
                                  <span className="text-danger">
                                    Enter address.
                                  </span>
                                )} */}
                            </div>
                          </div>

                          <div className="mb-3 row">
                            <div className="col-lg-2">
                              <button
                                type="submit"
                                className="btn btn-success  w-100"
                                data-bs-toggle="modal"
                                data-bs-target="#print"
                              >
                                Register
                              </button>
                            </div>
                            <div className="col-lg-2">
                              <button
                                type="button"
                                className="btn btn-success w-100"
                                data-bs-toggle="modal"
                                data-bs-target="#print"
                                onClick={handleSubmit(onUpdate)}
                              >
                                Update
                              </button>
                            </div>
                            <div className="col-lg-2">
                              <button
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#oldsearch"
                                className="btn btn-info w-100"
                                onClick={loadOldRoomData}
                              >
                                Old Search
                              </button>
                            </div>
                            <div className="col-lg-2">
                              <button
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#newsearch"
                                className="btn btn-primary w-100"
                                onClick={loadNewRoomData}
                              >
                                New Search
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="oldsearch"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Search customer in old data
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  Search by
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by name, address, room, contact"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={handleSearchOldCustomers}
                />
              </div>
              <table className="table  table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Room</th>
                    <th scope="col">Address</th>
                    <th scope="col">Age</th>
                    <th scope="col">Contact</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredOldRoomData &&
                    filteredOldRoomData.map((item, i) => (
                      <tr>
                        <th scope="row">{i + 1}</th>
                        <td>{item.custName}</td>
                        <td>{item.roomNo}</td>
                        <td>{item.address}</td>
                        <td>{item.age}</td>
                        <td>{item.contact}</td>
                        <td>
                          <button
                            data-bs-dismiss="modal"
                            type="button"
                            className="btn btn-success"
                            onClick={() => setOldSearched(item)}
                          >
                            Select
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="newsearch"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Search customer in new data
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  Search by
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by name, address, room, contact"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={handleSearchNewCustomers}
                />
              </div>
              <table className="table  table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Room</th>
                    <th scope="col">Address</th>
                    <th scope="col">Age</th>
                    <th scope="col">Contact</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredNewRoomData &&
                    filteredNewRoomData.map((item, i) => (
                      <tr>
                        <th scope="row">{i + 1}</th>
                        <td>{item.custName}</td>
                        <td>{item.roomNo}</td>
                        <td>{item.address}</td>
                        <td>{item.age}</td>
                        <td>{item.contact}</td>
                        <td>
                          <button
                            data-bs-dismiss="modal"
                            type="button"
                            className="btn btn-success me-3"
                            onClick={() => setNewSearched(item)}
                          >
                            Select
                          </button>
                          <button
                            data-bs-dismiss="modal"
                            type="button"
                            className="btn btn-success me-3"
                            data-bs-toggle="modal"
                            data-bs-target="#print"
                            onClick={() => setDataToPrint(item)}
                          >
                            Print
                          </button>
                          <button
                            data-bs-dismiss="modal"
                            type="button"
                            className="btn btn-danger"
                            onClick={() => onDelete(item)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="print"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              {regData && (
                <div
                  style={{
                    display: "block",
                    border: "1px solid #000",
                    padding: "10px",
                  }}
                  id="print-invoice"
                >
                  <div
                    style={{
                      display: "inline-flex",
                      width: "100%",
                      padding: "5px 0px",
                    }}
                  >
                    <div style={{ width: "30%", textAlign: "left" }}>
                      (र. नं. ए. २८६)
                    </div>
                    <div style={{ width: "40%", textAlign: "center" }}>
                      {" "}
                      ।। जगदंबा माता प्रसन्न ।।
                    </div>
                    <div style={{ width: "30%", textAlign: "end" }}>
                      फोन : 9657535184
                    </div>
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      width: "100%",
                      padding: "5px 0px",
                    }}
                  >
                    <div style={{ width: "30%", textAlign: "left" }}> </div>
                    <div
                      style={{
                        width: "40%",
                        textAlign: "center",
                        fontSize: "25px",
                      }}
                    >
                      <strong> श्री जगदंबा देवस्थान ट्रस्ट</strong>
                    </div>
                    <div style={{ width: "30%", textAlign: "end" }}>
                      7769031307
                    </div>
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      width: "100%",
                      borderTop: "1px solid #000",
                      borderBottom: "1px solid #000",
                      padding: "5px 0px",
                    }}
                  >
                    <div style={{ width: "60%", textAlign: "left" }}>
                      {" "}
                      पत्ता : कोटमगाव खु. येवला, ता. - येवला, जि. - नाशिक{" "}
                    </div>
                    <div style={{ width: "40%", textAlign: "end" }}>
                      दि. ०३/१०/२०२४
                    </div>
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      width: "100%",
                      padding: "5px 0px",
                    }}
                  >
                    <div
                      style={{
                        width: "60%",
                        textAlign: "left",
                        fontSize: "18px",
                      }}
                    >
                      {" "}
                      <strong> घटी बसणाऱ्या भाविकांसाठी पावती </strong>{" "}
                    </div>
                    <div style={{ width: "40%", textAlign: "end" }}>
                      टोकन नंबर : {regData.regId}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      width: "100%",
                      padding: "5px 0px",
                    }}
                  >
                    <div style={{ width: "60%", textAlign: "left" }}>
                      {" "}
                      <strong> नाव श्री./सौ. :</strong> {regData.custName}
                    </div>
                    <div style={{ width: "40%", textAlign: "end" }}>
                      <strong>वय : </strong> {regData.age}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      width: "100%",
                      padding: "5px 0px",
                    }}
                  >
                    <div style={{ width: "60%", textAlign: "left" }}>
                      {" "}
                      <strong> पत्ता :</strong> {regData.address}
                    </div>
                    <div style={{ width: "40%", textAlign: "end" }}>
                      <strong>खोली नंबर : </strong> {regData.roomNo}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      width: "100%",
                      padding: "5px 0px",
                    }}
                  >
                    <div style={{ width: "60%", textAlign: "left" }}>
                      {" "}
                      <strong> मोबाईल नंबर :</strong> {regData.contact}
                    </div>
                    <div style={{ width: "40%", textAlign: "end" }}>
                      <strong>रक्कम (Rs.): </strong> 200
                    </div>
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      width: "100%",
                      borderTop: "1px solid #000",
                      borderBottom: "1px solid #000",
                      padding: "5px 0px",
                    }}
                  >
                    <div style={{ width: "50%", textAlign: "left" }}>
                      {" "}
                      <strong> Contact Information : 9657535784</strong>{" "}
                    </div>
                    <div style={{ width: "50%", textAlign: "end" }}>
                      <strong>Website: https://www.jagdambamata.com </strong>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      width: "100%",
                      padding: "5px 0px",
                    }}
                  >
                    <div style={{ width: "15%", textAlign: "center" }}>
                      {" "}
                      <strong> सूचना : </strong>{" "}
                    </div>
                    <div style={{ width: "85%", textAlign: "left" }}>
                      <li>
                        सर्व भाविकांनी मंदिराच्या पूर्ण परिसरात व आपआपलया खोलीची
                        (रूमची) स्वच्छता ठेवावी.{" "}
                      </li>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      width: "100%",
                      padding: "5px 0px",
                    }}
                  >
                    <div style={{ width: "15%", textAlign: "left" }}>
                      {" "}
                      <strong></strong>{" "}
                    </div>
                    <div style={{ width: "85%", textAlign: "left" }}>
                      <li>
                        रूममध्ये कोणत्याही प्रकारचे गैरवर्तवणूक चालणार नाही.
                      </li>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      width: "100%",
                      padding: "5px 0px",
                    }}
                  >
                    <div style={{ width: "15%", textAlign: "left" }}>
                      {" "}
                      <strong></strong>{" "}
                    </div>
                    <div style={{ width: "85%", textAlign: "left" }}>
                      <li>रूममधील पाण्याचा व लाईटचा योग्य वापर करावा.</li>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      width: "100%",
                      padding: "5px 0px",
                    }}
                  >
                    <div style={{ width: "15%", textAlign: "left" }}>
                      {" "}
                      <strong></strong>{" "}
                    </div>
                    <div style={{ width: "85%", textAlign: "left" }}>
                      <li>
                        सर्व भाविकांनी स्वतःच्या वस्तूंची काळजी स्वतः घ्यावी.
                      </li>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      width: "100%",
                      padding: "5px 0px",
                    }}
                  >
                    <div style={{ width: "15%", textAlign: "left" }}>
                      {" "}
                      <strong></strong>{" "}
                    </div>
                    <div style={{ width: "85%", textAlign: "left" }}>
                      <li>तपासणी करता पावती स्वतः जवळ ठेवावी.</li>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "inline-flex",
                      width: "100%",
                      borderTop: "1px solid #000",
                      padding: "5px 0px 0px 0px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "15px",
                      }}
                    >
                      <strong> श्री जगदंबा देवस्थानकरिता </strong>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                onClick={printInvoice}
                type="button"
                class="btn btn-primary"
              >
                Print
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Registration;
