import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import UserProtectedRoute from "../router/UserProtectedRoute";
// import AdminProtectedRoute from "../service/AdminProtectedRoute";

import Site from "../site/Site";
//import SiteContainer from "../containers/SiteContainer";
//import User from '../user/User'
// import Admin from "../admin/Admin";
// site views
import Home from "../site/Home";
import About from "../site/About";
import ManageRooms from "../site/ManageRooms";
import NewBooking from "../site/NewBooking";
import Registration from "../site/Registration";
import ViewDetails from "../site/ViewDetails";
import EditBooking from "../site/EditBooking";
import CheckoutDetails from "../site/CheckoutDetails";
import Login from "../user/Login";
import Print from "../user/Print";
import PrintReceipt from "../site/PrintReceipt";

function Routers() {
  return (
    <Router>
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/print" exact component={Print} />
        <Route path="/print-receipt" exact component={PrintReceipt} />
        <UserProtectedRoute path="/:path?" exact>
          <Site>
            <Switch>
              <UserProtectedRoute path="/" exact component={Registration} />
              <UserProtectedRoute path="/home" component={Home} />
              <UserProtectedRoute path="/about" component={About} />
              <UserProtectedRoute path="/rooms" component={ManageRooms} />
              <UserProtectedRoute
                path="/registration"
                component={Registration}
              />
              <UserProtectedRoute path="/new-booking" component={NewBooking} />
              <UserProtectedRoute path="/view" component={ViewDetails} />
              <UserProtectedRoute
                path="/edit-booking"
                component={EditBooking}
              />
              <UserProtectedRoute
                path="/checkout-details"
                component={CheckoutDetails}
              />
            </Switch>
          </Site>
        </UserProtectedRoute>

        {/* <Route path='/user/:path?' exact>
          <User>
            <Switch>
              <Route path='/user/profile' component={UserProfile} />
            </Switch>
          </User>
        </Route> */}

        {/* <Route path="/admin/:path?" exact>
            <Admin>
              <Switch>
                <AdminProtectedRoute
                  path="/admin/profile"
                  component={AdminProfile}
                />
                <AdminProtectedRoute
                  path="/admin/update-logo"
                  component={UpdateLogo}
                />
                 <AdminProtectedRoute
                  path="/admin/manage-categories"
                  component={ManageCategories}
                />
                <AdminProtectedRoute
                  path="/admin/category"
                  component={CategoryForm}
                />
                <AdminProtectedRoute
                  path="/admin/manage-sub-categories"
                  component={ManageSubCategories}
                />
                 <AdminProtectedRoute
                  path="/admin/sub-category"
                  component={SubCategoryForm}
                />
                 <AdminProtectedRoute
                  path="/admin/manage-products"
                  component={ManageProducts}
                />
                <AdminProtectedRoute
                  path="/admin/product"
                  component={ProductForm}
                />
                 <AdminProtectedRoute
                  path="/admin/manage-colors"
                  component={ManageColors}
                />
                 <AdminProtectedRoute
                  path="/admin/color"
                  component={ColorForm}
                />
                 <AdminProtectedRoute
                  path="/admin/manage-images"
                  component={ManageImages}
                />
                <AdminProtectedRoute
                  path="/admin/image"
                  component={ImagesForm}
                />
                 <AdminProtectedRoute
                  path="/admin/manage-descriptions"
                  component={ManageDescription}
                />
                  <AdminProtectedRoute
                  path="/admin/descriptions"
                  component={DescriptionForm}
                />
                 <AdminProtectedRoute
                  path="/admin/manage-informations"
                  component={ManageInformations}
                />
                  <AdminProtectedRoute
                  path="/admin/informations"
                  component={InformationForm}
                />
                 <AdminProtectedRoute
                  path="/admin/manage-sizes"
                  component={ManageSizes}
                />
                  <AdminProtectedRoute
                  path="/admin/sizes"
                  component={SizeForm}
                />
                  <AdminProtectedRoute
                  path="/admin/manage-slider"
                  component={ManageSlider}
                />
                  <AdminProtectedRoute
                  path="/admin/slider"
                  component={SliderForm}
                />
                <AdminProtectedRoute
                  path="/admin/orders"
                  component={PlacedOrders}
                />
              </Switch>
            </Admin>
          </Route> */}
      </Switch>
    </Router>
  );
}

export default Routers;
