import axios from "axios";

// const BASE_URL = "http://localhost:5000/room-booking-api";
// const BASE_URL = "https://booking.jagdambamata.com/room-booking-api";

const BASE_URL = "https://roombookingapi.atopd.in/room-booking-api";
class Service {
  getRoomStatus() {
    return axios.get(BASE_URL + "/rooms");
  }

  getSingleRoom(id) {
    return axios.get(BASE_URL + "/rooms/get-single-room/" + id);
  }

  login(data) {
    return axios.post(BASE_URL + "/orginfo/login", data);
  }

  newBooking(data) {
    return axios.post(BASE_URL + "/bookings/new-booking", data);
  }

  saveRoom(data) {
    return axios.post(BASE_URL + "/rooms/add-room", data);
  }

  updateRooms(data) {
    return axios.put(BASE_URL + "/rooms/update-room", data);
  }

  deleteRoom(data) {
    return axios.delete(BASE_URL + "/rooms/delete-room/" + data);
  }

  updateBooking(data) {
    return axios.put(BASE_URL + "/bookings/update-booking", data);
  }

  getBookingByRoom(data) {
    return axios.get(BASE_URL + "/bookings/get-booking-by-room/" + data);
  }

  checkoutBooking(data) {
    return axios
      .put(BASE_URL + "/bookings/checkout-booking", data)
      .catch(function (error) {
        console.log(error);
      });
  }

  paidBooking(data) {
    return axios.put(BASE_URL + "/bookings/paid-booking", data);
  }

  deleteBooking(data) {
    return axios.delete(BASE_URL + "/bookings/delete-booking/" + data);
  }

  deleteCheckoutBooking(data) {
    return axios.delete(BASE_URL + "/checkoutbookings/delete-booking/" + data);
  }

  getCheckoutBookingByRoom(data) {
    return axios.get(
      BASE_URL + "/checkoutbookings/get-booking-by-room/" + data
    );
  }

  getCheckoutBookingBySearch(data) {
    return axios.get(
      BASE_URL + "/checkoutbookings/get-booking-by-search/" + data
    );
  }

  /////////////////ghati apis start/////////////////////

  getAllRooms() {
    return axios.get(BASE_URL + "/registration/get-all-rooms");
  }

  getAllCustomersByRooms(id) {
    return axios.get(
      BASE_URL + "/registration/get-all-current-customers-by-room/" + id
    );
  }

  getAllOldCustomers() {
    return axios.get(BASE_URL + "/registration/get-all-old-customers");
  }

  getAllCurrentCustomers() {
    return axios.get(BASE_URL + "/registration/get-all-current-customers");
  }

  registerCustomers(data) {
    return axios.post(BASE_URL + "/registration/add-customer", data);
  }

  updateCustomers(data) {
    return axios.put(BASE_URL + "/registration/update-customer", data);
  }

  deleteCustomers(data) {
    return axios.post(BASE_URL + "/registration/delete-customer", data);
  }

  getRoomStatistics() {
    return axios.get(BASE_URL + "/registration/get-new-room-statistics");
  }
}

export default new Service();
