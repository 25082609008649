import React, { useState, useEffect } from "react";
import Service from "../service/Service";
import Loader from "../service/Loader";
import { useForm } from "react-hook-form";
import * as moment from "moment";
import "./Print.css";

function PrintReceipt() {
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [oldRoomData, setOldRoomData] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset,
    watch,
  } = useForm();

  useEffect(() => {
    loadOldRoomData();
  }, []);

  const loadOldRoomData = () => {
    setIsLoading(true);
    Service.getAllOldCustomers().then((res) => {
      setOldRoomData(res.data);
      console.log(res.data);
      setIsLoading(false);
    });
  };

  const printInvoice = () => {
    const prtContent = document.getElementById("print-invoice");
    prtContent.style.padding = "20px";
    const WinPrint = window.open(
      "",
      "",
      "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
    );
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="container-fluid div-border mt-4" id="print-invoice">
        <div className="row">
          <div className="col-lg-3 col-3 text-start">(र. नं. ए. २८६)</div>
          <div className="col-lg-6 col-6"> ।। जगदंबा माता प्रसन्न ।।</div>
          <div className="col-lg-3 col-3 text-end">फोन : 9657535184</div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-3"> </div>
          <div
            className="col-lg-6 col-6 h4"
            style={{
              fontSize: "50px",
              border: "2px solid red",
              textAlign: "center",
            }}
          >
            <strong>श्री जगदंबा देवस्थान ट्रस्ट </strong>
          </div>
          <div className="col-lg-3 col-3 text-end">7769031307</div>
        </div>
        <div className="row  div-border-tb">
          <div className="col-lg-8 col-8 text-start">
            {" "}
            पत्ता : कोटमगाव खु. येवला, ता. - येवला, जि. - नाशिक{" "}
          </div>
          <div className="col-lg-4 col-4 text-end">दि. ०३/१०/२०२४</div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-8 text-start h5">
            {" "}
            <strong> घटी बसणाऱ्या भाविकांसाठी पावती </strong>{" "}
          </div>
          <div className="col-lg-4 col-4 text-end">टोकन नंबर : 333.00</div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-8 text-start">
            {" "}
            <strong> नाव श्री./सौ. :</strong> कोणाचे तरी नाव{" "}
          </div>
          <div className="col-lg-4 col-4 text-end">
            <strong>वय : </strong> 55
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-8 text-start">
            {" "}
            <strong> पत्ता :</strong> येवला{" "}
          </div>
          <div className="col-lg-4 col-4 text-end">
            <strong>खोली नंबर : </strong> 311
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-8 text-start">
            {" "}
            <strong> मोबाईल नंबर :</strong> 0{" "}
          </div>
          <div className="col-lg-4 col-4 text-end">
            <strong>रक्कम (Rs.): </strong> 150.00{" "}
          </div>
        </div>
        <div className="row div-border-tb">
          <div className="col-lg-6 col-6 text-start">
            {" "}
            <strong> Contact Information : 9657535784</strong>{" "}
          </div>
          <div className="col-lg-6 col-6 text-end">
            <strong>Website: https://www.jagdambamata.com </strong>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2 col-2 text-end">
            {" "}
            <strong> सूचना : </strong>{" "}
          </div>
          <div className="col-lg-10 col-10 text-start">
            <li>
              सर्व भाविकांनी मंदिराच्या पूर्ण परिसरात व आपआपलया खोलीची (रूमची)
              स्वच्छता ठेवावी.{" "}
            </li>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2 col-2">
            {" "}
            <strong></strong>{" "}
          </div>
          <div className="col-lg-10 col-10 text-start">
            <li>रूममध्ये कोणत्याही प्रकारचे गैरवर्तवणूक चालणार नाही.</li>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2 col-2">
            {" "}
            <strong></strong>{" "}
          </div>
          <div className="col-lg-10 col-10 text-start">
            <li>रूममधील पाण्याचा व लाईटचा योग्य वापर करावा.</li>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2 col-2">
            {" "}
            <strong></strong>{" "}
          </div>
          <div className="col-lg-10 col-10 text-start">
            <li>सर्व भाविकांनी स्वतःच्या वस्तूंची काळजी स्वतः घ्यावी.</li>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2 col-2">
            {" "}
            <strong></strong>{" "}
          </div>
          <div className="col-lg-10 col-10 text-start">
            <li>तपासणी करता पावती स्वतः जवळ ठेवावी.</li>
          </div>
        </div>
        <div className="row div-border-tb">
          <div className="col-lg-12 col-12 text-center h5">
            {" "}
            <strong> श्री जगदंबा देवस्थानकरिता </strong>
          </div>
        </div>
      </div>

      <button
        type="button"
        // class="btn btn-secondary"
        // data-bs-dismiss="modal"
        onClick={printInvoice}
      >
        Print
      </button>
    </>
  );
}

export default PrintReceipt;
