import './App.css';
import Routers from "./router/Routers";

function App() {
  return (
    <div>
      <Routers></Routers>
    </div>
  );
}

export default App;
