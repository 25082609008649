import React, { useState, useEffect } from "react";
import Service from "../service/Service";
import Loader from "../service/Loader";
import { useHistory } from "react-router-dom";

function Home() {
  const [isLoading, setIsLoading] = useState(false);
  const [roomsData, setRoomsData] = useState([]);
  const [roomStatistics, setRoomStatistics] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [roomNo, setRoomNo] = useState("101");

  useEffect(() => {
    // loadRoomData();
    loadRoomStatistics();
  }, []);

  const loadRoomData = () => {
    setIsLoading(true);
    Service.getAllRooms().then((res) => {
      setRoomsData(res.data);
      setIsLoading(false);
    });
  };

  const loadRoomStatistics = () => {
    setIsLoading(true);
    Service.getRoomStatistics().then((res) => {
      setRoomStatistics(res.data);
      setIsLoading(false);
    });
  };

  const loadCustomer = (data) => {
    setIsLoading(true);
    setRoomNo(data.roomNo);
    Service.getAllCustomersByRooms(data.roomNo)
      .then((res) => {
        setCustomers(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="row">
                <div className="col-xl-12">
                  <div className="row">
                    {roomStatistics.map((item, i) => (
                      <div
                        role="button"
                        className="col-xl-3 col-sm-6"
                        key={i}
                        data-bs-toggle="modal"
                        data-bs-target="#view"
                        onClick={() => loadCustomer(item)}
                      >
                        <div className="card booking">
                          <div
                            className={
                              "card-body rounded " +
                              (item.count == 0
                                ? "bg-success"
                                : item.count > 15
                                ? "bg-danger"
                                : "bg-warning")
                            }
                          >
                            <div className="booking-status d-flex align-items-center">
                              {/* <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="28"
                                  height="20"
                                  viewBox="0 0 28 20"
                                >
                                  <path
                                    d="M27,14V7a1,1,0,0,0-1-1H6A1,1,0,0,0,5,7v7a3,3,0,0,0-3,3v8a1,1,0,0,0,2,0V24H28v1a1,1,0,0,0,2,0V17A3,3,0,0,0,27,14ZM7,8H25v6H24V12a2,2,0,0,0-2-2H19a2,2,0,0,0-2,2v2H15V12a2,2,0,0,0-2-2H10a2,2,0,0,0-2,2v2H7Zm12,6V12h3v2Zm-9,0V12h3v2ZM4,17a1,1,0,0,1,1-1H27a1,1,0,0,1,1,1v5H4Z"
                                    transform="translate(-2 -6)"
                                    fill="var(--primary)"
                                  />
                                </svg>
                              </span> */}
                              <div className="ms-4">
                                <h2 className="mb-0 font-w600">
                                  {item.roomNo} = {item.count}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="view"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                List of customer in {roomNo} room
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  Search by
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by name, address, room, contact"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={handleSearchNewCustomers}
                />
              </div> */}
              <table className="table  table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Room</th>
                    <th scope="col">Address</th>
                    <th scope="col">Age</th>
                    <th scope="col">Contact</th>
                    {/* <th scope="col">Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {customers &&
                    customers.map((item, i) => (
                      <tr>
                        <th scope="row">{i + 1}</th>
                        <td>{item.custName}</td>
                        <td>{item.roomNo}</td>
                        <td>{item.address}</td>
                        <td>{item.age}</td>
                        <td>{item.contact}</td>
                        {/* <td>
                          <button
                            data-bs-dismiss="modal"
                            type="button"
                            className="btn btn-success me-3"
                            onClick={() => setNewSearched(item)}
                          >
                            Select
                          </button>
                          <button
                            data-bs-dismiss="modal"
                            type="button"
                            className="btn btn-success me-3"
                            data-bs-toggle="modal"
                            data-bs-target="#print"
                            onClick={() => setDataToPrint(item)}
                          >
                            Print
                          </button>
                          <button
                            data-bs-dismiss="modal"
                            type="button"
                            className="btn btn-danger"
                            onClick={() => onDelete(item)}
                          >
                            Delete
                          </button>
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
