import React, { useState, useEffect } from "react";
import Service from "../service/Service";
import Loader from "../service/Loader";
import * as moment from "moment";

function CheckoutDetails() {
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [roomsData, setRoomsData] = useState([]);
  const [bookingsData, setBookingsData] = useState([]);

  useEffect(() => {
    loadRoomData();
  }, []);

  const loadRoomData = () => {
    setIsLoading(true);
    Service.getRoomStatus().then((res) => {
      console.log(res);
      setRoomsData(res.data);
      setIsLoading(false);
    });
  };

  const loadCheckoutBookingsByRoom = (value) => {
    if (value) {
      setIsLoading(true);
      Service.getCheckoutBookingByRoom(value).then((res) => {
        setBookingsData(res.data);
        setIsLoading(false);
      });
    }
  };

  const loadCheckoutBookingsBySearch = (value) => {
    //setIsLoading(true);
    Service.getCheckoutBookingBySearch(value).then((res) => {
      setBookingsData(res.data);
      //setIsLoading(false);
    });
  };

  const deleteRecord = (data) => {
    if (
      window.confirm(
        "Confirm are you sure to delete record of : " + data.CustName + " ..?"
      )
    ) {
      setIsLoading(true);
      Service.deleteCheckoutBooking(data.CBId).then((res) => {
        if (res.data.success) {
          setShowMsg(true);
          setMsgClass(
            "alert alert-secondary solid alert-dismissible fade show"
          );
          setMsgText(res.data.success);
          loadCheckoutBookingsByRoom("All");
        }
        setIsLoading(false);
      });
    }
  };

  function tConv24(time24) {
    var ts = time24;
    var H = +String(ts).substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + String(ts).substr(2, 3) + ampm;
    return ts;
  }

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">List of CheckOut Bookings</h4>
                </div>

                <div className="card-body">
                  {showMsg == true ? (
                    <div className={msgClass}>
                      <strong>{msgText}</strong>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="btn-close"
                      ></button>
                    </div>
                  ) : null}

                  <div>
                    <form className="needs-validation">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="mb-3 row">
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Select Room :
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <select
                                className="default-select wide form-control"
                                onChange={(e) =>
                                  loadCheckoutBookingsByRoom(e.target.value)
                                }
                              >
                                <option value="">Search by room</option>
                                <option value="All">All Bookings</option>
                                {roomsData.map((item, i) => (
                                  <option key={i} value={item.RId}>
                                    {item.RNo}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustom01"
                            >
                              Search By Name :
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search by customer name."
                                onChange={(e) =>
                                  loadCheckoutBookingsBySearch(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="card-header">
                    <h4 className="card-title"></h4>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-responsive-md">
                      <thead>
                        <tr>
                          <th>
                            <strong>#</strong>
                          </th>
                          <th>
                            <strong>Customer</strong>
                          </th>
                          <th>
                            <strong>Contact</strong>
                          </th>
                          <th>
                            <strong>Check-In</strong>
                          </th>
                          <th>
                            <strong>Check-Out</strong>
                          </th>
                          <th>
                            <strong>Rate</strong>
                          </th>
                          <th>
                            <strong>Total</strong>
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookingsData.map((item, i) => (
                          <tr key={i}>
                            <td>
                              <strong>{i + 1}</strong>
                            </td>
                            <td>{item.CustName}</td>
                            <td>{item.Contact}</td>
                            <td>
                              {moment(item.CheckInDate).format("DD/MM/YYYY")}
                              <br />
                              {tConv24(item.CheckInTime)}
                            </td>
                            <td>
                              {moment(item.CheckOutDate).format("DD/MM/YYYY")}
                              <br />
                              {tConv24(item.CheckOutTime)}
                            </td>
                            <td>{item.Rate}</td>
                            <td>{item.TotalAmount}</td>
                            <td>
                              <div className="d-flex">
                                <a
                                  onClick={() => deleteRecord(item)}
                                  href="http://"
                                  className="btn btn-danger shadow btn-xs sharp"
                                >
                                  <i className="fa fa-trash"></i>
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CheckoutDetails;
